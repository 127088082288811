<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id,外键{zb_user.id}" prop="userId">
        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="用户项目id,外键{zb_user_project.id}" prop="userProjectId">
        <a-input v-model="form.userProjectId" placeholder="请输入用户项目id,外键{zb_user_project.id}" />
      </a-form-model-item>
      <a-form-model-item label="订单状态,0-已请求,1-已报价,2-制作中,3-已完成,4-已关闭" prop="status">
        <a-select placeholder="请选择订单状态" option-filter-prop="children" style="width: 100%" v-model="form.status">
          <a-select-option value="0">已请求</a-select-option>
          <a-select-option value="1">已报价 </a-select-option>
          <a-select-option value="2">制作中</a-select-option>
          <a-select-option value="3">已完成</a-select-option>
          <a-select-option value="4">已关闭</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="项目名称" prop="projectName">
        <a-input v-model="form.projectName" placeholder="请输入项目名称" />
      </a-form-model-item>
      <a-form-model-item label="定金金额" prop="deposit">
        <a-input v-model="form.deposit" placeholder="请输入定金金额" />
      </a-form-model-item>
      <a-form-model-item label="支付状态,0-待支付,1-已支付" prop="payStatus">
        <a-select placeholder="请选择支付状态" option-filter-prop="children" style="width: 100%" v-model="form.status">
          <a-select-option value="0">待支付</a-select-option>
          <a-select-option value="1">已支付 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="支付时间" prop="payTime">
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="报价截止时间" prop="quotationDeadline">
        <a-date-picker style="width: 100%" v-model="form.quotationDeadline" format="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="报告,外键{zb_file.id},多个使用逗号隔开" prop="report">
        <a-input v-model="form.report" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="订单进度百分比" prop="progressPercentage">
        <a-input v-model="form.progressPercentage" placeholder="请输入订单进度百分比" />
      </a-form-model-item>
      <a-form-model-item label="服务者id,外键{zb_user_waiter.id}" prop="userWaiterId">
        <a-input v-model="form.userWaiterId" placeholder="请输入服务者id,外键{zb_user_waiter.id}" />
      </a-form-model-item>
      <a-form-model-item label="定金已抵扣金额" prop="deductionMoney">
        <a-input v-model="form.deductionMoney" placeholder="请输入定金已抵扣金额" />
      </a-form-model-item>
      <a-form-model-item label="订单过期时间" prop="expirationTime">
        <a-date-picker style="width: 100%" v-model="form.expirationTime" format="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getServe, addServe, updateServe } from '@/api/order/serve'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        userProjectId: null,

        status: 0,

        projectName: null,

        deposit: null,

        payStatus: 0,

        payTime: null,

        quotationDeadline: null,

        report: null,

        progressPercentage: null,

        userWaiterId: null,

        deductionMoney: null,

        expirationTime: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [{ required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }],
        userProjectId: [{ required: true, message: '用户项目id,外键{zb_user_project.id}不能为空', trigger: 'blur' }],
        status: [
          { required: true, message: '订单状态,0-已请求,1-已报价,2-制作中,3-已完成,4-已关闭不能为空', trigger: 'blur' },
        ],
        projectName: [{ required: true, message: '项目名称不能为空', trigger: 'blur' }],
        deposit: [{ required: true, message: '定金金额不能为空', trigger: 'blur' }],
        payStatus: [{ required: true, message: '支付状态,0-待支付,1-已支付不能为空', trigger: 'blur' }],
        report: [{ required: true, message: '报告,外键{zb_file.id},多个使用逗号隔开不能为空', trigger: 'blur' }],
        progressPercentage: [{ required: true, message: '订单进度百分比不能为空', trigger: 'blur' }],
        userWaiterId: [{ required: true, message: '服务者id,外键{zb_user_waiter.id}不能为空', trigger: 'blur' }],
        deductionMoney: [{ required: true, message: '定金已抵扣金额不能为空', trigger: 'blur' }],
      },
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        userProjectId: null,
        status: 0,
        projectName: null,
        deposit: null,
        payStatus: 0,
        payTime: null,
        quotationDeadline: null,
        report: null,
        progressPercentage: null,
        userWaiterId: null,
        deductionMoney: null,
        expirationTime: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getServe({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateServe(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addServe(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
