import request from '@/utils/request'


// 查询服务订单列表
export function listServe(query) {
  return request({
    url: '/order/order-serve/list',
    method: 'get',
    params: query
  })
}

// 查询服务订单分页
export function pageServe(query) {
  return request({
    url: '/order/order-serve/page',
    method: 'get',
    params: query
  })
}

// 查询服务订单详细
export function getServe(data) {
  return request({
    url: '/order/order-serve/detail',
    method: 'get',
    params: data
  })
}

// 新增服务订单
export function addServe(data) {
  return request({
    url: '/order/order-serve/add',
    method: 'post',
    data: data
  })
}

// 修改服务订单
export function updateServe(data) {
  return request({
    url: '/order/order-serve/edit',
    method: 'post',
    data: data
  })
}

// 删除服务订单
export function delServe(data) {
  return request({
    url: '/order/order-serve/delete',
    method: 'post',
    data: data
  })
}
